import React, { useEffect, useState } from "react";

const styles = {
  pageContainer: {
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#f4f6f8",
    minHeight: "100vh",
    maxWidth: "100%", // Full width for mobile
    margin: "0 auto",
    boxSizing: "border-box", // Ensure padding doesn't cause overflow
  },
  header: {
    textAlign: "center",
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginBottom: "20px",
    color: "#374151",
  },
  orderCardContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(280px, 1fr))", // Adjusted min-width for mobile screens
    gap: "20px",
  },
  orderCard: {
    backgroundColor: "#ffffff",
    border: "1px solid #e5e7eb",
    borderRadius: "8px",
    padding: "20px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
  cardHeader: {
    fontSize: "1.25rem",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  cardDetails: {
    marginBottom: "10px",
    color: "#6b7280",
  },
  cardActions: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row", // Ensure the elements are in a horizontal row
    gap: "10px", // Add gap to space out elements more evenly
  },
  fulfillButton: {
    padding: "10px",
    backgroundColor: "#16a34a",
    color: "#ffffff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "1rem",
    fontWeight: "bold",
  },
  cancelButton: {
    padding: "10px",
    backgroundColor: "#ef4444",
    color: "#ffffff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "1rem",
    fontWeight: "bold",
  },
  inputField: {
    padding: "10px",
    border: "1px solid #e5e7eb",
    borderRadius: "5px",
    fontSize: "1rem",
  },
  returnQuantityInput: {
    padding: "10px",
    border: "1px solid #e5e7eb",
    borderRadius: "5px",
    fontSize: "1rem",
    marginTop: "10px",
    width: "100%", // Ensure it takes full width
  },
  commentInput: {
    padding: "10px",
    border: "1px solid #e5e7eb",
    borderRadius: "5px",
    fontSize: "1rem",
    marginTop: "10px",
    width: "100%", // Ensure it takes full width
  },
  imageInput: {
    padding: "10px",
    fontSize: "1rem",
    marginTop: "10px",
  },
  saveButton: {
    padding: "10px",
    backgroundColor: "#2563eb",
    color: "#ffffff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "1rem",
    fontWeight: "bold",
    textAlign: "center",
    marginTop: "10px",
  },
};

const sampleOrders = [
  {
    id: 1,
    title: "Office Supplies",
    description: "Order 50 packs of A4 paper and 20 boxes of pens.",
    fulfilled: false,
    returnQuantity: 0,
    comment: "",
    image: "",
  },
  {
    id: 2,
    title: "Maintenance Tools",
    description: "Purchase 10 sets of screwdrivers and 5 power drills.",
    fulfilled: true,
    returnQuantity: 0,
    comment: "",
    image: "",
  },
  {
    id: 3,
    title: "Cleaning Supplies",
    description: "Restock 30 bottles of floor cleaner and 20 packs of sponges.",
    fulfilled: false,
    returnQuantity: 0,
    comment: "",
    image: "",
  },
];

const OrdersPage = () => {
  const [orders, setOrders] = useState(() => {
    const savedOrders = localStorage.getItem("orders");
    return savedOrders ? JSON.parse(savedOrders) : sampleOrders;
  });
  const [returnQuantity, setReturnQuantity] = useState("");
  const [comment, setComment] = useState("");
  const [image, setImage] = useState(null);
  const [activeOrder, setActiveOrder] = useState(null);

  useEffect(() => {
    localStorage.setItem("orders", JSON.stringify(orders));
  }, [orders]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  const saveForm = () => {
    if (returnQuantity && !comment) {
      alert("Please provide a comment if you are returning any quantity.");
      return;
    }

    setOrders(
      orders.map((order) =>
        order.id === activeOrder
          ? {
              ...order,
              fulfilled: true,
              returnQuantity: returnQuantity ? Number(returnQuantity) : 0,
              comment: comment || "",
              image: image ? URL.createObjectURL(image) : "",
            }
          : order
      )
    );
    setReturnQuantity("");
    setComment("");
    setImage(null);
    setActiveOrder(null);
  };

  const cancelFulfill = () => {
    setActiveOrder(null);
    setReturnQuantity("");
    setComment("");
    setImage(null);
  };

  return (
    <div style={styles.pageContainer}>
      <div style={styles.orderCardContainer}>
      {orders.map((order) => (
  <div key={order.id} style={styles.orderCard}>
    <div style={styles.cardHeader}>{order.title}</div>
    <div style={styles.cardDetails}>{order.description}</div>
    <div style={styles.cardActions}>
      {!activeOrder && (
        <span>{order.fulfilled ? "Fulfilled" : "Pending"}</span>
      )}
      {(!order.fulfilled && activeOrder === null) && (
        <button
          style={styles.fulfillButton}
          onClick={() => setActiveOrder(order.id)}
        >
          Fulfill
        </button>
      )}
      {activeOrder === order?.id && (
        <div style={{ width: "100%" }}>
          <div>
            <input
              type="number"
              placeholder="Return Quantity"
              style={styles.returnQuantityInput}
              value={returnQuantity}
              onChange={(e) => setReturnQuantity(e.target.value)}
            />
            <textarea
              placeholder="Comment (required if returning quantity)"
              style={styles.commentInput}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <input
              type="file"
              onChange={handleImageChange}
              style={styles.imageInput}
            />
            <div>
              <button style={styles.saveButton} onClick={saveForm}>
                Save
              </button>
              <button
                style={styles.cancelButton}
                onClick={cancelFulfill}
              >
                Cancel Fulfill
              </button>
            </div>
          </div>
        </div>
      )}
      {order.fulfilled && (
        <div>
          <div>Return Quantity: {order.returnQuantity}</div>
          <div>Comment: {order.comment}</div>
          {order.image && <img src={order.image} alt="Returned item" />}
        </div>
      )}
    </div>
  </div>
))}

      </div>
    </div>
  );
};

export default OrdersPage;
