import React, { useEffect, useState } from 'react';

const vehicleSampleData = [
    {
        id: 1,
        make: 'Toyota',
        model: 'Camry',
        year: 2019,
        regNo: 'ABC-1234',
        color: 'Silver',
        type: 'Sedan',
        currentMileage: 45672,
        lastServiceDate: '2024-02-15',
        status: 'Active',
        fuelType: 'Gasoline',
        assignedTo: 'Sales Department',
    },
    {
        id: 2,
        make: 'Ford',
        model: 'Explorer',
        year: 2020,
        regNo: 'DEF-5678',
        color: 'Black',
        type: 'SUV',
        currentMileage: 32145,
        lastServiceDate: '2024-03-10',
        status: 'Maintenance',
        fuelType: 'Gasoline',
        assignedTo: 'Management',
    },
    {
        id: 3,
        make: 'Mercedes-Benz',
        model: 'Sprinter',
        year: 2018,
        regNo: 'GHI-9012',
        color: 'White',
        type: 'Van',
        currentMileage: 78234,
        lastServiceDate: '2024-01-20',
        status: 'Active',
        fuelType: 'Diesel',
        assignedTo: 'Logistics',
    },
    {
        id: 4,
        make: 'Chevrolet',
        model: 'Silverado',
        year: 2021,
        regNo: 'JKL-3456',
        color: 'Red',
        type: 'Truck',
        currentMileage: 24567,
        lastServiceDate: '2024-03-05',
        status: 'Active',
        fuelType: 'Gasoline',
        assignedTo: 'Maintenance Department',
    },
    {
        id: 5,
        make: 'Caterpillar',
        model: '950M',
        year: 2017,
        regNo: 'MNO-7890',
        color: 'Yellow',
        type: 'Wheel Loader',
        currentMileage: 5678,
        lastServiceDate: '2024-02-28',
        status: 'Active',
        fuelType: 'Diesel',
        assignedTo: 'Construction Site',
    },
    {
        id: 6,
        make: 'Tesla',
        model: 'Model 3',
        year: 2022,
        regNo: 'PQR-2345',
        color: 'Blue',
        type: 'Electric Sedan',
        currentMileage: 15432,
        lastServiceDate: '2024-03-15',
        status: 'Active',
        fuelType: 'Electric',
        assignedTo: 'Executive Team',
    },
];

// Reuse the existing InputSection component
function InputSection({ label, value, onChange, type = 'text' }) {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '10px',
            }}
        >
            <div
                style={{
                    backgroundColor: '#f0f0f0',
                    padding: '8px',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    borderBottom: '2px solid #333',
                    marginBottom: '5px',
                }}
            >
                {label}
            </div>
            <input
                type={type}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                style={{
                    width: '100%',
                    padding: '10px',
                    fontSize: '16px',
                    border: '2px solid #333',
                    borderRadius: '4px',
                    backgroundColor: '#fff',
                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                }}
            />
        </div>
    );
}

function VehicleChecklistApp() {
    const [vehicles, setVehicles] = useState(() => {
        const savedVehicles = localStorage.getItem('vehicleChecklistVehicles');
        return savedVehicles
            ? JSON.parse(savedVehicles)
            : vehicleSampleData.map((vehicle) => ({
                  ...vehicle,
                  checklistStatus: 'pending',
                  inspectionDate: '',
                  inspectorName: '',
                  checklistItems: {
                      safetyBelts: { status: false, remarks: '' },
                      brakesAndSteering: { status: false, remarks: '' },
                      engine: { status: false, remarks: '' },
                      transmission: { status: false, remarks: '' },
                      liquidLevelCheck: {
                          oil: { status: false, remarks: '' },
                          coolant: { status: false, remarks: '' },
                          brakeFluid: { status: false, remarks: '' },
                      },
                  },
              }));
    });

    const [currentVehicle, setCurrentVehicle] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterStatus, setFilterStatus] = useState('all');

    const [formData, setFormData] = useState({
        inspectorName: '',
        date: '',
        regNo: '',
        currentMileage: '',
        checklistItems: {
            safetyBelts: { status: false, remarks: '' },
            brakesAndSteering: { status: false, remarks: '' },
            engine: { status: false, remarks: '' },
            transmission: { status: false, remarks: '' },
            heaterAirConditioning: { status: false, remarks: '' },
            wipers: { status: false, remarks: '' },
            headlightsHighBeam: { status: false, remarks: '' },
            headlightsLowBeam: { status: false, remarks: '' },
            turnSignals: { status: false, remarks: '' },
            brakeLightsTailLights: { status: false, remarks: '' },
            doors: { status: false, remarks: '' },
            windowsWindshield: { status: false, remarks: '' },
            radio: { status: false, remarks: '' },
            horn: { status: false, remarks: '' },
            tiresTreadCondition: { status: false, remarks: '' },
            properInflation: { status: false, remarks: '' },
            lugWrenchJack: { status: false, remarks: '' },
            fireExtinguisher: { status: false, remarks: '' },
            firstAidKit: { status: false, remarks: '' },
            accidentInformationPacket: { status: false, remarks: '' },
            liquidLevelCheck: {
                radiator: { status: false, remarks: '' },
                oil: { status: false, remarks: '' },
                autoTransmission: { status: false, remarks: '' },
                powerSteering: { status: false, remarks: '' },
                brakes: { status: false, remarks: '' },
                windowWasher: { status: false, remarks: '' },
            },
            insurance: { status: false, remarks: '' },
            inspection: { status: false, remarks: '' },
        },
        status: 'pending',
    });

    // Save vehicles to localStorage whenever the vehicles array changes
    useEffect(() => {
        localStorage.setItem('vehicles', JSON.stringify(vehicles));
    }, [vehicles]);

    const addVehicle = () => {
        if (!formData.regNo) {
            alert('Please enter a registration number');
            return;
        }

        // Check if vehicle already exists
        const existingVehicleIndex = vehicles.findIndex((v) => v.regNo === formData.regNo);

        const newVehicleData = {
            ...formData,
            id: Date.now(),
        };

        if (existingVehicleIndex > -1) {
            // Update existing vehicle
            const updatedVehicles = [...vehicles];
            updatedVehicles[existingVehicleIndex] = newVehicleData;
            setVehicles(updatedVehicles);
        } else {
            // Add new vehicle
            setVehicles([...vehicles, newVehicleData]);
        }

        // Reset form
        setCurrentVehicle(null);
        setFormData({
            inspectorName: '',
            date: '',
            regNo: '',
            currentMileage: '',
            checklistItems: Object.fromEntries(
                Object.entries(formData.checklistItems).map(([key, value]) => [
                    key,
                    typeof value === 'object' && 'status' in value
                        ? { status: false, remarks: '' }
                        : Object.fromEntries(
                              Object.entries(value).map(([subKey, subValue]) => [
                                  subKey,
                                  { status: false, remarks: '' },
                              ])
                          ),
                ])
            ),
            status: 'pending',
        });
    };

    const editVehicle = (vehicle) => {
        setCurrentVehicle(vehicle);
        setFormData(vehicle);
    };

    const markVehicleComplete = (vehicleId) => {
        const updatedVehicles = vehicles.map((vehicle) =>
            vehicle.id === vehicleId ? { ...vehicle, status: 'completed' } : vehicle
        );
        setVehicles(updatedVehicles);
    };

    const filteredVehicles = vehicles.filter((vehicle) => {
        const matchesSearch = vehicle.regNo.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesStatus = filterStatus === 'all' || vehicle.status === filterStatus;
        return matchesSearch && matchesStatus;
    });

    const handleItemChange = (section, field, value, isStatus = false) => {
        setFormData((prev) => {
            const updatedFormData = { ...prev };

            if (section === 'liquidLevelCheck') {
                updatedFormData.checklistItems.liquidLevelCheck[field] = isStatus
                    ? {
                          status: value,
                          remarks: updatedFormData.checklistItems.liquidLevelCheck[field].remarks,
                      }
                    : {
                          status: updatedFormData.checklistItems.liquidLevelCheck[field].status,
                          remarks: value,
                      };
            } else {
                updatedFormData.checklistItems[section] = isStatus
                    ? {
                          status: value,
                          remarks: updatedFormData.checklistItems[section].remarks,
                      }
                    : {
                          status: updatedFormData.checklistItems[section].status,
                          remarks: value,
                      };
            }

            return updatedFormData;
        });
    };

    return (
        <div style={{ display: 'flex', fontFamily: 'Arial, sans-serif' }}>
            {/* Vehicle List Section */}
            <div
                style={{
                    width: '30%',
                    padding: '20px',
                    borderRight: '1px solid #ccc',
                    height: '100vh',
                    overflowY: 'auto',
                }}
            >
                <h2>Vehicle List</h2>

                {/* Search and Filter */}
                <div style={{ marginBottom: '20px' }}>
                    <InputSection
                        label="Search Vehicles"
                        value={searchTerm}
                        onChange={setSearchTerm}
                    />

                    <div style={{ marginBottom: '10px' }}>
                        <div>
                            Filter Status:
                            <select
                                value={filterStatus}
                                onChange={(e) => setFilterStatus(e.target.value)}
                                style={{ marginLeft: '10px', padding: '5px' }}
                            >
                                <option value="all">All</option>
                                <option value="pending">Pending</option>
                                <option value="completed">Completed</option>
                            </select>
                        </div>
                    </div>
                </div>

                {/* Vehicle List */}
                <div>
                    {filteredVehicles.map((vehicle) => (
                        <div
                            key={vehicle.id}
                            style={{
                                border: '1px solid #ddd',
                                padding: '10px',
                                marginBottom: '10px',
                                backgroundColor:
                                    vehicle.status === 'completed' ? '#e6f3e6' : '#f9f9f9',
                            }}
                        >
                            <div>Reg No: {vehicle.regNo}</div>
                            <div>Inspector: {vehicle.inspectorName}</div>
                            <div>Date: {vehicle.date}</div>
                            <div
                                style={{
                                    fontWeight: 'bold',
                                    color: vehicle.status === 'completed' ? 'green' : 'orange',
                                }}
                            >
                                Status: {vehicle.status}
                            </div>
                            <div style={{ marginTop: '10px' }}>
                                <button
                                    type="submit"
                                    onClick={() => editVehicle(vehicle)}
                                    style={{ marginRight: '10px', padding: '5px 10px' }}
                                >
                                    Edit
                                </button>
                                {vehicle.status !== 'completed' && (
                                    <button
                                        type="submit"
                                        onClick={() => markVehicleComplete(vehicle.id)}
                                        style={{
                                            backgroundColor: 'green',
                                            color: 'white',
                                            padding: '5px 10px',
                                        }}
                                    >
                                        Mark Complete
                                    </button>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Checklist Section */}
            <div style={{ width: '70%', padding: '20px' }}>
                <h1
                    style={{
                        textAlign: 'center',
                        textDecoration: 'underline',
                        marginBottom: '20px',
                    }}
                >
                    DAILY VEHICLE CHECKLIST
                </h1>

                {/* Header Inputs */}
                <div
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                        gap: '20px',
                        marginBottom: '20px',
                    }}
                >
                    <InputSection
                        label="INSPECTOR NAME"
                        value={formData.inspectorName}
                        onChange={(value) =>
                            setFormData((prev) => ({ ...prev, inspectorName: value }))
                        }
                    />
                    <InputSection
                        label="DATE"
                        value={formData.date}
                        onChange={(value) => setFormData((prev) => ({ ...prev, date: value }))}
                        type="date"
                    />
                    <InputSection
                        label="REG. NO."
                        value={formData.regNo}
                        onChange={(value) => setFormData((prev) => ({ ...prev, regNo: value }))}
                    />
                    <InputSection
                        label="CURRENT MILEAGE"
                        value={formData.currentMileage}
                        onChange={(value) =>
                            setFormData((prev) => ({ ...prev, currentMileage: value }))
                        }
                    />
                </div>

                <table
                    style={{
                        width: '100%',
                        borderCollapse: 'collapse',
                        marginBottom: '20px',
                    }}
                >
                    <thead>
                        <tr style={{ backgroundColor: '#f0f0f0' }}>
                            <th style={{ border: '1px solid #000', padding: '10px' }}>ITEM</th>
                            <th
                                style={{
                                    border: '1px solid #000',
                                    padding: '10px',
                                    width: '10%',
                                }}
                            >
                                OK
                            </th>
                            <th
                                style={{
                                    border: '1px solid #000',
                                    padding: '10px',
                                    width: '10%',
                                }}
                            >
                                NOT OK
                            </th>
                            <th style={{ border: '1px solid #000', padding: '10px' }}>REMARKS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(formData.checklistItems).map(([key, value]) => {
                            // Skip nested liquid level check
                            if (typeof value === 'object' && 'radiator' in value) {
                                return Object.entries(value).map(([liquidKey, liquidValue]) => (
                                    <tr key={`liquid-${liquidKey}`}>
                                        <td
                                            style={{
                                                border: '1px solid #000',
                                                padding: '10px',
                                                textTransform: 'uppercase',
                                            }}
                                        >
                                            {liquidKey}
                                        </td>
                                        <td
                                            style={{
                                                border: '1px solid #000',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={liquidValue.status}
                                                onChange={(e) =>
                                                    handleItemChange(
                                                        'liquidLevelCheck',
                                                        liquidKey,
                                                        e.target.checked,
                                                        true
                                                    )
                                                }
                                                style={{ transform: 'scale(1.5)' }}
                                            />
                                        </td>
                                        <td
                                            style={{
                                                border: '1px solid #000',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={!liquidValue.status}
                                                onChange={(e) =>
                                                    handleItemChange(
                                                        'liquidLevelCheck',
                                                        liquidKey,
                                                        !e.target.checked,
                                                        true
                                                    )
                                                }
                                                style={{ transform: 'scale(1.5)' }}
                                            />
                                        </td>
                                        <td style={{ border: '1px solid #000', padding: '10px' }}>
                                            <input
                                                type="text"
                                                value={liquidValue.remarks}
                                                onChange={(e) =>
                                                    handleItemChange(
                                                        'liquidLevelCheck',
                                                        liquidKey,
                                                        e.target.value
                                                    )
                                                }
                                                style={{
                                                    width: '100%',
                                                    border: '1px solid #ccc',
                                                    padding: '5px',
                                                    borderRadius: '4px',
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ));
                            }

                            // Regular checklist items
                            if (typeof value === 'object' && 'status' in value) {
                                return (
                                    <tr key={key}>
                                        <td
                                            style={{
                                                border: '1px solid #000',
                                                padding: '10px',
                                                textTransform: 'uppercase',
                                            }}
                                        >
                                            {key.replace(/([A-Z])/g, ' $1').trim()}
                                        </td>
                                        <td
                                            style={{
                                                border: '1px solid #000',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={value.status}
                                                onChange={(e) =>
                                                    handleItemChange(
                                                        key,
                                                        'status',
                                                        e.target.checked,
                                                        true
                                                    )
                                                }
                                                style={{ transform: 'scale(1.5)' }}
                                            />
                                        </td>
                                        <td
                                            style={{
                                                border: '1px solid #000',
                                                textAlign: 'center',
                                            }}
                                        >
                                            <input
                                                type="checkbox"
                                                checked={!value.status}
                                                onChange={(e) =>
                                                    handleItemChange(
                                                        key,
                                                        'status',
                                                        !e.target.checked,
                                                        true
                                                    )
                                                }
                                                style={{ transform: 'scale(1.5)' }}
                                            />
                                        </td>
                                        <td style={{ border: '1px solid #000', padding: '10px' }}>
                                            <input
                                                type="text"
                                                value={value.remarks}
                                                onChange={(e) =>
                                                    handleItemChange(key, 'remarks', e.target.value)
                                                }
                                                style={{
                                                    width: '100%',
                                                    border: '1px solid #ccc',
                                                    padding: '5px',
                                                    borderRadius: '4px',
                                                }}
                                            />
                                        </td>
                                    </tr>
                                );
                            }
                            return null;
                        })}
                    </tbody>
                </table>
                {/* Save Vehicle Button */}
                <div style={{ textAlign: 'center', marginBottom: '20px' }}>
                    <button
                        type="submit"
                        onClick={addVehicle}
                        style={{
                            padding: '10px 20px',
                            backgroundColor: '#4CAF50',
                            color: 'white',
                            border: 'none',
                            width: '100%',
                            borderRadius: '5px',
                        }}
                    >
                        {currentVehicle ? 'Update Vehicle' : 'Save Vehicle'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default VehicleChecklistApp;
