import React, { useState } from "react";
import BottomNavigation from "../../Components/Navbar/AppBar";
import AccidentsPage from "./Accidents";
import MaintenancePage from "./Maintenaince";
import OrdersPage from "./Orders";
import DriverSafetyTalk from "./Safety";

const DriverDashboard = () => {
  // Inline styles
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      fontFamily: "Arial, sans-serif",
      backgroundColor: "#f8f9fa",
      minHeight: "100vh",
      color: "#333",
    },
    topNavbar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      padding: "15px 20px",
      backgroundColor: "#ffffff",
      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
      position: "fixed",
      top: 0,
      left: 0,
      zIndex: 1000,
    },
    title: {
      fontSize: "20px",
      fontWeight: "bold",
      color: "#333",
    },
    settingsIcon: {
      cursor: "pointer",
      color: "#666",
      width: "24px",
      height: "24px",
    },
    content: {
      marginTop: "70px", // Account for fixed top navbar
      padding: "20px",
      width: "100%",
      maxWidth: "400px",
    },
    grid: {
      display: "grid",
      gridTemplateColumns: "1fr",
      gap: "15px",
      width: "100%",
      maxWidth: "400px",
      marginTop: "20px",
    },
    // ... (rest of the existing styles remain the same)
  };

  const SettingsIcon = () => (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 24 24" 
      fill="currentColor" 
      style={styles.settingsIcon}
    >
      <path d="M19.14 12.94c.04-.3.06-.61.06-.94 0-.32-.02-.64-.07-.94l2.03-1.58a.49.49 0 00.12-.61l-1.92-3.32a.488.488 0 00-.59-.22l-2.39.96c-.5-.38-1.03-.7-1.62-.94l-.36-2.54a.484.484 0 00-.48-.41h-3.84c-.24 0-.43.17-.47.41l-.36 2.54c-.59.24-1.13.57-1.62.94l-2.39-.96a.505.505 0 00-.59.22L2.74 9.87a.493.493 0 00.12.61l2.03 1.58c-.05.3-.09.63-.09.94s.02.64.07.94l-2.03 1.58a.49.49 0 00-.12.61l1.92 3.32c.12.22.37.29.59.22l2.39-.96c.5.38 1.03.7 1.62.94l.36 2.54c.05.24.24.41.48.41h3.84c.24 0 .44-.17.47-.41l.36-2.54c.59-.24 1.13-.57 1.62-.94l2.39.96c.22.09.47 0 .59-.22l1.92-3.32a.49.49 0 00-.12-.61l-2.03-1.58zM12 15.6c-1.98 0-3.6-1.62-3.6-3.6s1.62-3.6 3.6-3.6 3.6 1.62 3.6 3.6-1.62 3.6-3.6 3.6z" />
    </svg>
  );

  const [activeTab, setActiveTab] = useState("Maintenance");
  const [showSettings, setShowSettings] = useState(false);

  const handleSettingsClick = () => {
    setShowSettings(true);
    // TODO: Implement settings modal or page
  };

  return (
    <div style={styles.container}>
      {/* Top Navigation Bar */}
      <div style={styles.topNavbar}>
        <div style={styles.title}>Driver Dashboard</div>
        <div onClick={handleSettingsClick}>
          <SettingsIcon />
        </div>
      </div>

      {/* Content Area */}
      <div style={styles.content}>
        {activeTab === "Accidents" ? (
          <AccidentsPage />
        ) : activeTab === "Maintenance" ? (
          <MaintenancePage />
        ) : activeTab === "Orders" ? (
          <OrdersPage />
        ) : activeTab === "Safety" ? (
          <DriverSafetyTalk />
        ) : null}
      </div>

      <BottomNavigation activeTab={activeTab} setActiveTab={setActiveTab} />

      {/* Footer */}
      <div style={{...styles.footer, marginTop: "40px"}}>
        © 2024 Driver Dashboard. All rights reserved.
      </div>

      {/* TODO: Add settings modal/page when setShowSettings(true) is called */}
    </div>
  );
};

export default DriverDashboard;