import React, { useState } from 'react';
import Select from 'react-select';

const styles = {
    container: {
        padding: '30px',
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#f4f4f4',
        borderRadius: '8px',
        boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
        maxWidth: '800px',
        margin: '0 auto',
    },
    title: {
        textAlign: 'center',
        marginBottom: '20px',
        color: '#2C3E50',
        fontSize: '24px',
        fontWeight: 'bold',
    },
    form: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(240px, 1fr))',
        gap: '15px',
        marginBottom: '20px',
    },
    input: {
        padding: '10px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        width: '100%',
        boxSizing: 'border-box',
    },
    buttonContainer: {
        gridColumn: '1 / -1', // Span the entire row
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    buttonSubmit: {
        padding: '12px 20px',
        backgroundColor: '#007BFF',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        flexGrow: 1, // Takes available space
        marginRight: '10px',
    },
    buttonCancel: {
        padding: '10px 15px',
        backgroundColor: '#6c757d',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '14px',
    },
};

const selectStyles = {
    control: (base) => ({
        ...base,
        padding: '5px',
        borderRadius: '5px',
        border: '1px solid #ccc',
    }),
};

function AddAllocation({ onClose }) {
    const [shipments, setShipments] = useState([]);
    const [formData, setFormData] = useState({
        ShipmentNO: '',
        DriverName: null,
        VehicleRegNO: null,
        CustomerName: null,
        Trip: '',
        Cases: '',
    });

    const drivers = [
        { value: 'Martin Kabocha', label: 'Martin Kabocha' },
        { value: 'John Mwangi', label: 'John Mwangi' },
        { value: 'Ben Kiberenge', label: 'Ben Kiberenge' },
        { value: 'Abraham Hamisi', label: 'Abraham Hamisi' },
    ];

    const vehicles = [
        { value: 'KBH559C', label: 'KBH559C' },
        { value: 'KAJ360B', label: 'KAJ360B' },
        { value: 'KAJ361B', label: 'KAJ361B' },
        { value: 'KBS644Z', label: 'KBS644Z' },
    ];

    const customers = [
        { value: 'Acme Corporation', label: 'Acme Corporation' },
        { value: 'Global Traders', label: 'Global Traders' },
        { value: 'Blue Ocean Ltd', label: 'Blue Ocean Ltd' },
        { value: 'Fast Logistics', label: 'Fast Logistics' },
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSelectChange = (selectedOption, action) => {
        setFormData({ ...formData, [action.name]: selectedOption });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const newShipment = {
            ...formData,
            DriverName: formData.DriverName?.value,
            VehicleRegNO: formData.VehicleRegNO?.value,
            CustomerName: formData.CustomerName?.value,
        };
        const updatedShipments = [...shipments, newShipment];
        setShipments(updatedShipments);

        // Save to local storage
        localStorage.setItem('shipments', JSON.stringify(updatedShipments));

        setFormData({
            ShipmentNO: '',
            DriverName: null,
            VehicleRegNO: null,
            CustomerName: null,
            Trip: '',
            Cases: '',
        });
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.title}>Shipment Management</h1>

            <form onSubmit={handleSubmit} style={styles.form}>
                <input
                    type="text"
                    name="ShipmentNO"
                    placeholder="Shipment No"
                    value={formData.ShipmentNO}
                    onChange={handleChange}
                    style={styles.input}
                    required
                />
                <Select
                    name="DriverName"
                    options={drivers}
                    placeholder="Select Driver"
                    value={formData.DriverName}
                    onChange={handleSelectChange}
                    styles={selectStyles}
                    isSearchable
                    required
                />
                <Select
                    name="VehicleRegNO"
                    options={vehicles}
                    placeholder="Select Vehicle"
                    value={formData.VehicleRegNO}
                    onChange={handleSelectChange}
                    styles={selectStyles}
                    isSearchable
                    required
                />
                <Select
                    name="CustomerName"
                    options={customers}
                    placeholder="Select Customer"
                    value={formData.CustomerName}
                    onChange={handleSelectChange}
                    styles={selectStyles}
                    isSearchable
                    required
                />
                <input
                    type="text"
                    name="Trip"
                    placeholder="Trip Description"
                    value={formData.Trip}
                    onChange={handleChange}
                    style={styles.input}
                    required
                />
                <input
                    type="number"
                    name="Cases"
                    placeholder="Number of Cases"
                    value={formData.Cases}
                    onChange={handleChange}
                    style={styles.input}
                    required
                />
                <div style={styles.buttonContainer}>
                    <button type="submit" style={styles.buttonSubmit}>
                        Add Shipment
                    </button>
                    <button type="button" onClick={() => onClose()} style={styles.buttonCancel}>
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
}

export default AddAllocation;
