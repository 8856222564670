import React from 'react';

const styles = {
  navigationContainer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: '#f9fafb',
    borderTop: '1px solid #e5e7eb',
    display: 'flex',
    justifyContent: 'space-around',
    padding: '10px 0',
    boxShadow: '0 -2px 4px rgba(0, 0, 0, 0.1)',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
  },
  navItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    flex: '0 0 auto', // Allow items to shrink or grow if needed
    width: '20%', // Adjust to fit 5 items perfectly
    padding: '5px',
    transition: 'color 0.3s ease, transform 0.2s ease',
  },
  navItemActive: {
    color: '#3b82f6', // Active item color
    transform: 'scale(1.1)', // Slight scaling for active item
  },
  navItemInactive: {
    color: '#6b7280', // Inactive item color
  },
  navIcon: {
    width: '24px',
    height: '24px',
    marginBottom: '4px',
  },
  navLabel: {
    fontSize: '0.75rem',
    fontWeight: '500',
  },
};

const Icons = {
  Home: () => (
    <svg style={styles.navIcon} fill="currentColor" viewBox="0 0 24 24">
      <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
    </svg>
  ),
  Maintenance: () => (
    <svg style={styles.navIcon} fill="currentColor" viewBox="0 0 24 24">
      <path d="M21 6h-4V5a2 2 0 0 0-2-2h-6a2 2 0 0 0-2 2v1H3a1 1 0 0 0-1 1v4c0 .55.45 1 1 1h1v7a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-7h1c.55 0 1-.45 1-1V7a1 1 0 0 0-1-1zM11 5h2v1h-2V5zm8 15H5v-7h14v7zm2-9H3V7h18v4z" />
    </svg>
  ),
  Orders: () => (
    <svg style={styles.navIcon} fill="currentColor" viewBox="0 0 24 24">
      <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm7 16H5V5h2v3h10V5h2v14z" />
    </svg>
  ),
  Accidents: () => (
    <svg style={styles.navIcon} fill="currentColor" viewBox="0 0 24 24">
      <path d="M14.69 2.21L4.33 11.64c-.64.58-.61 1.53.04 2.1l7.51 6.99c.65.57 1.62.57 2.27 0l7.51-6.99c.65-.57.68-1.52.04-2.1L16.81 2.21c-.64-.58-1.68-.58-2.12 0zM12 15.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm0-4.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" />
    </svg>
  ),
  Safety: () => (
    <svg style={styles.navIcon} fill="currentColor" viewBox="0 0 24 24">
      <path d="M12 2L1 6v6c0 5.25 3.88 10.74 11 13 7.12-2.26 11-7.75 11-13V6L12 2zm0 2.2L20 8v4c0 4.36-3.17 9.15-8 11.01C7.17 17.15 4 12.36 4 8V8l8-3.8zM12 11a2 2 0 110 4 2 2 0 010-4z" />
    </svg>
  ),
};

const BottomNavigation = ({activeTab, setActiveTab}) => {
  

  const navItems = [
    // { key: 'Se', label: 'Home' },
    { key: 'Maintenance', label: 'Maintenance' },
    { key: 'Orders', label: 'Orders' },
    { key: 'Accidents', label: 'Accidents' },
    { key: 'Safety', label: 'Safety' },
  ];

  return (
    <div style={styles.navigationContainer}>
      {navItems.map((item) => {
        const Icon = Icons[item.key];
        return (
          <div
            key={item.key}
            style={{
              ...styles.navItem,
              ...(activeTab === item.key ? styles.navItemActive : styles.navItemInactive),
            }}
            onClick={() => setActiveTab(item.key)}
          >
            <Icon />
            <span style={styles.navLabel}>{item.label}</span>
          </div>
        );
      })}
    </div>
  );
};

export default BottomNavigation;
