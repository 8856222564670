import React, { useEffect, useState } from "react";
import VehicleSafetyChecklistItems from "./CheckListItem";

const VehicleSafetyChecklist = () => {
  const styles = {
    container: {
      minHeight: '100vh',
      backgroundColor: '#f3f4f6',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '1rem',
      fontFamily: 'Arial, sans-serif',
    },
    contentWrapper: {
      width: '100%',
      maxWidth: '400px',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },
    completedStatus: {
      backgroundColor: '#dcfce7',
      padding: '1rem',
      borderRadius: '0.5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
    },
    pendingStatus: {
      backgroundColor: '#fef3c7',
      padding: '1rem',
      borderRadius: '0.5rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
    },
    timeRemainingContainer: {
      backgroundColor: '#e0f2fe',
      padding: '1rem',
      borderRadius: '0.5rem',
      display: 'flex',
      flexDirection: 'column',
      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
    },
    timerText: {
      display: 'flex',
      alignItems: 'center',
      color: '#dc2626',
      fontWeight: 'bold',
    },
    mainButton: {
      backgroundColor: '#3b82f6',
      color: 'white',
      padding: '0.75rem 1.5rem',
      border: 'none',
      borderRadius: '0.5rem',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease',
      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
    },
    disabledButton: {
      backgroundColor: '#9ca3af',
      color: 'white',
      padding: '0.75rem 1.5rem',
      border: 'none',
      borderRadius: '0.5rem',
      cursor: 'not-allowed',
    },
    maintenanceImage: {
      width: '150px',
      height: '150px',
      opacity: 0.7,
      marginTop: '1rem',
    },
    errorText: {
      color: '#dc2626',
      textAlign: 'center',
      fontWeight: 'bold',
      marginTop: '1rem',
      maxWidth: '300px',
    },
  };

  const [checklistStatus, setChecklistStatus] = useState({
    initialized: false,
    completed: false,
    completedTime: null,
    timeRemaining: null,
    isPastDeadline: false,
  });
  const [timerBlink, setTimerBlink] = useState(true);
  const [addCheckList, setAddChecklist] = useState(false);

  useEffect(() => {
    const checkDailyStatus = () => {
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const deadline = new Date(today.setHours(22, 0, 0, 0)); // 10 PM today
      const savedStatus = localStorage.getItem("vehicleChecklistStatus");

      if (savedStatus) {
        try {
          const parsedStatus = JSON.parse(savedStatus);
          const completedDate = new Date(parsedStatus.completedTime);

          if (
            completedDate.getDate() === now.getDate() &&
            completedDate.getMonth() === now.getMonth() &&
            completedDate.getFullYear() === now.getFullYear()
          ) {
            setChecklistStatus({
              initialized: true,
              completed: true,
              completedTime: parsedStatus.completedTime,
              timeRemaining: null,
              isPastDeadline: false,
            });
            return;
          }
        } catch (error) {
          console.error("Error parsing checklist status:", error);
        }
      }

      // Checklist incomplete, check deadline
      if (now > deadline) {
        setChecklistStatus({
          initialized: true,
          completed: false,
          completedTime: null,
          timeRemaining: null,
          isPastDeadline: true,
        });
      } else {
        const remainingTime = deadline.getTime() - now.getTime();
        const hours = Math.floor(remainingTime / (1000 * 60 * 60));
        const minutes = Math.floor(
          (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

        setChecklistStatus({
          initialized: true,
          completed: false,
          completedTime: null,
          timeRemaining: `${hours}h ${minutes}m ${seconds}s`,
          isPastDeadline: false,
        });
      }
    };

    checkDailyStatus();
    const intervalId = setInterval(checkDailyStatus, 1000);
    const blinkInterval = setInterval(() => {
      setTimerBlink((prev) => !prev);
    }, 1000);

    return () => {
      clearInterval(intervalId);
      clearInterval(blinkInterval);
    };
  }, []);

  const handleAddChecklist = () => {
    setAddChecklist(true);
  };

  const renderInitialStatus = () => {
    if (!checklistStatus.initialized) return null;

    if (checklistStatus.completed) {
      return (
        <div style={styles.completedStatus}>
          <div>
            <span style={{color: '#166534', fontWeight: 'bold'}}>👍 Checklist Completed Today</span>
          </div>
        </div>
      );
    }

    if (addCheckList) {
      return <VehicleSafetyChecklistItems />;
    }

    if (checklistStatus.isPastDeadline) {
      return (
        <>
          <div style={styles.pendingStatus}>
            <div>
              <span style={{color: '#92400e', fontWeight: 'bold'}}>⚠️ Checklist Pending</span>
            </div>
          </div>
          <img
            src="https://cdn-icons-png.flaticon.com/512/3281/3281383.png"
            alt="Repair Icon"
            style={styles.maintenanceImage}
          />
          <div style={styles.errorText}>
            Your time has elapsed to fill the checklist today, please contact admin.
          </div>
        </>
      );
    }

    return (
      <div style={styles.timeRemainingContainer}>
        <h2 style={{marginBottom: '0.5rem', color: '#1e3a8a', fontSize: '1.25rem'}}>
          Daily Safety Checklist
        </h2>
        <div 
          style={{
            ...styles.timerText,
            visibility: timerBlink ? 'visible' : 'hidden',
          }}
        >
          ⏰ Time Remaining: {checklistStatus.timeRemaining}
        </div>
      </div>
    );
  };

  return (
    <div style={styles.container}>
      <div style={styles.contentWrapper}>
        {renderInitialStatus()}
        {!addCheckList && !checklistStatus.completed && !checklistStatus.isPastDeadline && (
          <button 
            style={styles.mainButton}
            onClick={handleAddChecklist}
            onMouseOver={(e) => e.target.style.backgroundColor = '#2563eb'}
            onMouseOut={(e) => e.target.style.backgroundColor = '#3b82f6'}
          >
            Start Checklist
          </button>
        )}
        {checklistStatus.isPastDeadline && (
          <button 
            style={styles.disabledButton}
            disabled
          >
            Checklist Closed
          </button>
        )}
      </div>
    </div>
  );
};

export default VehicleSafetyChecklist;