import React, { useEffect, useState } from 'react';

const styles = {
  pageContainer: {
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#f4f6f8",
    minHeight: "100vh",
    padding: "0", // Removed padding for full width
    margin: "0", // Ensure no margin around the page
  },
  container: {
    maxWidth: "100%", // Make sure it stretches to 100% of the width
    margin: "0 auto",
    padding: "20px",
  },
  header: {
    textAlign: "center",
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginBottom: "20px",
    color: "#374151",
  },
  repairCardContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))", // Ensure proper responsiveness
    gap: "20px", // Add space between the cards
    width: "100%",
    padding: "0 20px", // Add horizontal padding to prevent overlap
  },
  repairCard: {
    backgroundColor: "#ffffff",
    border: "1px solid #e5e7eb",
    borderRadius: "8px",
    padding: "20px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    width: "100%",
    boxSizing: "border-box", // Ensures padding does not overflow
  },
  cardHeader: {
    fontSize: "1.25rem",
    fontWeight: "bold",
    marginBottom: "10px",
    color: "#1f2937",
  },
  cardDetails: {
    marginBottom: "10px",
    color: "#6b7280",
  },
  addRepairForm: {
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    padding: "20px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    width: "100%",
  },
  inputField: {
    padding: "10px",
    border: "1px solid #e5e7eb",
    borderRadius: "5px",
    fontSize: "1rem",
    width: "100%",
  },
  addButton: {
    padding: "10px",
    backgroundColor: "#2563eb",
    color: "#ffffff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "1rem",
    fontWeight: "bold",
    textAlign: "center",
    width: "100%",
    transition: "background-color 0.3s ease",
  },
  toggleButton: {
    padding: "10px",
    backgroundColor: "#38bdf8",
    color: "#ffffff",
    width: "100%",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "1rem",
    fontWeight: "bold",
    marginBottom: "20px",
    textAlign: "center",
    transition: "background-color 0.3s ease",
  },
  statusBadge: {
    display: "inline-block",
    padding: "5px 10px",
    borderRadius: "4px",
    fontSize: "0.875rem",
    fontWeight: "semibold",
  },
  pendingStatus: {
    backgroundColor: "#fef3c7",
    color: "#92400e",
  },
  completedStatus: {
    backgroundColor: "#d1fae5",
    color: "#065f46",
  },
  imagePreview: {
    marginTop: "10px",
    maxWidth: "100%",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  },
};

const RepairsPage = () => {
  const [repairs, setRepairs] = useState(() => {
    const savedRepairs = localStorage.getItem("repairs");
    return savedRepairs ? JSON.parse(savedRepairs) : [];
  });
  
  const [newRepair, setNewRepair] = useState({ 
    title: "", 
    description: "",
    imageUrl: "" // Add imageUrl field
  });
  
  const [isAddingRepair, setIsAddingRepair] = useState(false);

  useEffect(() => {
    localStorage.setItem("repairs", JSON.stringify(repairs));
  }, [repairs]);

  const addRepair = () => {
    if (newRepair.title && newRepair.description) {
      const repairToAdd = {
        ...newRepair,
        id: Date.now(),
        status: "Pending",
        createdAt: new Date().toLocaleString()
      };

      setRepairs([...repairs, repairToAdd]);
      setNewRepair({ title: "", description: "", imageUrl: "" });
      setIsAddingRepair(false);
    } else {
      alert("Please fill in both title and description");
    }
  };

  const toggleAddRepairForm = () => {
    setIsAddingRepair(!isAddingRepair);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setNewRepair((prev) => ({ ...prev, imageUrl: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  const getStatusStyle = (status) => {
    return status === "Pending" 
      ? {...styles.statusBadge, ...styles.pendingStatus}
      : {...styles.statusBadge, ...styles.completedStatus};
  };

  return (
    <div style={styles.pageContainer}>
      <div style={styles.container}>
        <h1 style={styles.header}>Repair Tracker</h1>

        <button
          type="button"
          style={styles.toggleButton}
          onClick={toggleAddRepairForm}
          onMouseOver={(e) => e.target.style.backgroundColor = "#0ea5e9"}
          onMouseOut={(e) => e.target.style.backgroundColor = "#38bdf8"}
        >
          {isAddingRepair ? "Show Repair List" : "Add New Repair"}
        </button>

        {isAddingRepair ? (
          <div style={styles.repairCardContainer}>
            <form
              style={styles.addRepairForm}
              onSubmit={(e) => e.preventDefault()}
            >
              <input
                type="text"
                placeholder="Repair Title"
                style={styles.inputField}
                value={newRepair.title}
                onChange={(e) =>
                  setNewRepair({ ...newRepair, title: e.target.value })
                }
                required
              />
              <textarea
                placeholder="Repair Description"
                style={styles.inputField}
                rows="3"
                value={newRepair.description}
                onChange={(e) =>
                  setNewRepair({ ...newRepair, description: e.target.value })
                }
                required
              />
              <input
                type="file"
                accept="image/*"
                style={styles.inputField}
                onChange={handleImageChange}
              />
              {newRepair.imageUrl && (
                <img
                  src={newRepair.imageUrl}
                  alt="Repair Area"
                  style={styles.imagePreview}
                />
              )}
              <button 
                type="button" 
                style={styles.addButton}
                onClick={addRepair}
                onMouseOver={(e) => e.target.style.backgroundColor = "#1d4ed8"}
                onMouseOut={(e) => e.target.style.backgroundColor = "#2563eb"}
              >
                Add Repair
              </button>
            </form>
          </div>
        ) : (
          <div style={styles.repairCardContainer}>
            {repairs.map((repair) => (
              <div key={repair.id} style={styles.repairCard}>
                <div style={styles.cardHeader}>{repair.title}</div>
                <div style={styles.cardDetails}>{repair.description}</div>
                <div style={styles.cardDetails}>
                  <strong>Status:</strong>{" "}
                  <span style={getStatusStyle(repair.status)}>
                    {repair.status}
                  </span>
                </div>
                {repair.createdAt && (
                  <div style={{...styles.cardDetails, fontSize: "0.75rem", color: "#9ca3af"}}>
                    Created: {repair.createdAt}
                  </div>
                )}
                {repair.imageUrl && (
                  <img
                    src={repair.imageUrl}
                    alt="Repair Area"
                    style={styles.imagePreview}
                  />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default RepairsPage;
