import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function LoginPage() {
    const styles = {
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
            backgroundColor: '#f4f4f4',
            fontFamily: 'Arial, sans-serif',
        },
        card: {
            width: '100%',
            maxWidth: '400px',
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            padding: '20px',
        },
        header: {
            textAlign: 'center',
            marginBottom: '20px',
            color: '#333',
            fontSize: '24px',
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
        },
        label: {
            marginBottom: '5px',
            color: '#555',
        },
        input: {
            padding: '10px',
            border: '1px solid #ddd',
            borderRadius: '4px',
            fontSize: '16px',
        },
        button: {
            padding: '12px',
            backgroundColor: '#007bff',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            fontSize: '16px',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
        },
        errorMessage: {
            color: 'red',
            textAlign: 'center',
            marginBottom: '10px',
        },
    };

    const users = [
        { name: 'john_admin', password: 'admin123', type: 'admin' },
        { name: 'jane_driver', password: 'driver456', type: 'driver' },
        { name: 'mike_driver', password: 'driver789', type: 'driver' },
    ];

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const navigate = useNavigate();

    const handleLogin = (e) => {
        e.preventDefault();

        const user = users.find(
            (u) => u.name === username && u.password === password
        );

        if (user) {
            setError('');
            if (user.type === 'admin') {
                navigate('/adminHome');
            } else if (user.type === 'driver') {
                navigate('/driverHome');
            }
        } else {
            setError('Invalid username or password');
        }
    };

    return (
        <div style={styles.container}>
            <div style={styles.card}>
                <h2 style={styles.header}>Login</h2>
                <form onSubmit={handleLogin} style={styles.form}>
                    <div>
                        <label style={styles.label}>Username</label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="Enter your username"
                            required
                            style={styles.input}
                        />
                    </div>
                    <div>
                        <label style={styles.label}>Password</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter your password"
                            required
                            style={styles.input}
                        />
                    </div>
                    {error && <div style={styles.errorMessage}>{error}</div>}
                    <button type="submit" style={styles.button}>
                        Login
                    </button>
                </form>
            </div>
        </div>
    );
}

export default LoginPage;
