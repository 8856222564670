import React, { useEffect, useState } from 'react';

const styles = {
  pageContainer: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f4f6f8',
    minHeight: '100vh',
  },
  header: {
    textAlign: 'center',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: '#374151',
  },
  accidentCardContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
    gap: '20px',
  },
  accidentCard: {
    backgroundColor: '#ffffff',
    border: '1px solid #e5e7eb',
    borderRadius: '8px',
    padding: '20px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  cardHeader: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  cardDetails: {
    marginBottom: '10px',
    color: '#6b7280',
  },
  cardImage: {
    width: '100%',
    height: '200px',
    objectFit: 'cover',
    borderRadius: '5px',
    marginBottom: '10px',
  },
  addAccidentForm: {
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginTop: '20px',
    borderTop: '1px solid #e5e7eb',
    paddingTop: '10px',
    width: '100%',
  },
  inputField: {
    padding: '10px',
    border: '1px solid #e5e7eb',
    borderRadius: '5px',
    fontSize: '1rem',
    width: '100%',
  },
  addButton: {
    padding: '10px',
    backgroundColor: '#2563eb',
    color: '#ffffff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '1rem',
    fontWeight: 'bold',
    textAlign: 'center',
    width: '100%',
  },
  toggleFormButton: {
    padding: '10px',
    backgroundColor: '#34D399',
    color: '#ffffff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '1rem',
    fontWeight: 'bold',
    marginBottom: '20px',
    width: '100%',
  },
};

const AccidentsPage = () => {
  const [accidents, setAccidents] = useState(() => {
    const savedAccidents = localStorage.getItem('accidents');
    return savedAccidents ? JSON.parse(savedAccidents) : [];
  });
  const [newAccident, setNewAccident] = useState({
    title: '',
    description: '',
    date: '',
    location: '',
    thirdParty: {
      numberPlate: '',
      insurance: '',
      photo: [],
    },
    image: [],
  });
  const [showForm, setShowForm] = useState(false); // Toggle form visibility
  const [showList, setShowList] = useState(true); // Toggle list visibility

  useEffect(() => {
    localStorage.setItem('accidents', JSON.stringify(accidents));
  }, [accidents]);

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    if (files.length) {
      const readerPromises = files.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.onerror = (err) => reject(err);
          reader.readAsDataURL(file);
        });
      });

      Promise.all(readerPromises)
        .then((imageUrls) => {
          setNewAccident({
            ...newAccident,
            image: imageUrls,
          });
        })
        .catch((err) => console.error('Error uploading files', err));
    }
  };

  const handleThirdPartyPhotoUpload = (e) => {
    const files = Array.from(e.target.files);
    if (files.length) {
      const readerPromises = files.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.onerror = (err) => reject(err);
          reader.readAsDataURL(file);
        });
      });

      Promise.all(readerPromises)
        .then((imageUrls) => {
          setNewAccident({
            ...newAccident,
            thirdParty: {
              ...newAccident.thirdParty,
              photo: imageUrls,
            },
          });
        })
        .catch((err) => console.error('Error uploading third-party photos', err));
    }
  };

  const addAccident = () => {
    if (
      newAccident.title &&
      newAccident.description &&
      newAccident.date &&
      newAccident.location &&
      newAccident.image.length
    ) {
      setAccidents([...accidents, { ...newAccident, id: Date.now() }]);
      setNewAccident({
        title: '',
        description: '',
        date: '',
        location: '',
        thirdParty: {
          numberPlate: '',
          insurance: '',
          photo: [],
        },
        image: [],
      });
      setShowForm(false); // Hide form after adding accident
      setShowList(true); // Show the list after adding the accident
    }
  };

  return (
    <div style={styles.pageContainer}>
      <div style={styles.header}>Accidents and Incidents</div>

      <button
        style={styles.toggleFormButton}
        onClick={() => {
          setShowForm(!showForm);
          setShowList(true); // Show the list when toggling the form
        }}
      >
        {showForm ? 'Cancel' : 'Add Accident'}
      </button>

      {showForm && (
        <form style={styles.addAccidentForm} onSubmit={(e) => e.preventDefault()}>
          <input
            type="text"
            placeholder="Accident Title"
            style={styles.inputField}
            value={newAccident.title}
            onChange={(e) => setNewAccident({ ...newAccident, title: e.target.value })}
          />
          <textarea
            placeholder="Accident Description"
            style={styles.inputField}
            rows="3"
            value={newAccident.description}
            onChange={(e) => setNewAccident({ ...newAccident, description: e.target.value })}
          />
          <input
            type="date"
            style={styles.inputField}
            value={newAccident.date}
            onChange={(e) => setNewAccident({ ...newAccident, date: e.target.value })}
          />
          <input
            type="text"
            placeholder="Location"
            style={styles.inputField}
            value={newAccident.location}
            onChange={(e) => setNewAccident({ ...newAccident, location: e.target.value })}
          />
          <input
            type="text"
            placeholder="Third-Party Number Plate"
            style={styles.inputField}
            value={newAccident.thirdParty.numberPlate}
            onChange={(e) =>
              setNewAccident({
                ...newAccident,
                thirdParty: { ...newAccident.thirdParty, numberPlate: e.target.value },
              })
            }
          />
          <input
            type="text"
            placeholder="Third-Party Insurance"
            style={styles.inputField}
            value={newAccident.thirdParty.insurance}
            onChange={(e) =>
              setNewAccident({
                ...newAccident,
                thirdParty: { ...newAccident.thirdParty, insurance: e.target.value },
              })
            }
          />
          {/* <input
            type="file"
            accept="image/*"
            multiple
            style={styles.inputField}
            onChange={handleImageUpload}
          /> */}
          <input
            type="file"
            accept="image/*"
            multiple
            style={styles.inputField}
            onChange={handleThirdPartyPhotoUpload}
          />
          <button type="button" style={styles.addButton} onClick={addAccident}>
            Add Accident
          </button>
        </form>
      )}

      {!showForm && showList && (
        <div style={styles.accidentCardContainer}>
          {accidents.map((accident) => (
            <div key={accident.id} style={styles.accidentCard}>
             
              <div style={styles.cardHeader}>{accident.title}</div>
              <div style={styles.cardDetails}>Date: {accident.date}</div>
              <div style={styles.cardDetails}>Location: {accident.location}</div>
              <div style={styles.cardDetails}>Description: {accident.description}</div>
              <div style={styles.cardDetails}>
                <strong>Third Party:</strong>
                <div>Number Plate: {accident.thirdParty.numberPlate}</div>
                <div>Insurance: {accident.thirdParty.insurance}</div>
                {accident.thirdParty.photo.length > 0 && (
                  <div>
                    {accident.thirdParty.photo.map((photo, idx) => (
                      <img key={idx} src={photo} alt={`Third Party ${idx}`} style={styles.cardImage} />
                    ))}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AccidentsPage;
