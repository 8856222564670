import React, { useState } from 'react';
import Navbar from '../../Components/Navbar/Navbar';
import Sidebar from '../../Components/Sidebar/Sidebar';
import AddMaintenanceReport from './AddMaintenance';
import MaintenanceTable from './MaintenanceList';
import './orders.scss';

function Maintenence() {
    const [newpo, setNewPO] = useState(null);
    const [maintenanceReports, setMaintenanceReports] = useState([
        {
            truckId: 'TRK-101',
            maintenanceType: 'Oil Change',
            maintenanceDate: '2024-10-05',
            nextMaintenance: '2024-11-05',
            status: 'Completed',
            comments: 'Changed oil and filters',
            image: 'https://via.placeholder.com/150',
            detailsVisible: false,
        },
        {
            truckId: 'TRK-102',
            maintenanceType: 'Brake Check',
            maintenanceDate: '2024-10-10',
            nextMaintenance: '2024-11-10',
            status: 'Pending',
            comments: 'Brake pads replacement needed',
            image: 'https://via.placeholder.com/150',
            detailsVisible: false,
        },
        {
            truckId: 'TRK-103',
            maintenanceType: 'Tire Replacement',
            maintenanceDate: '2024-10-12',
            nextMaintenance: '2024-11-12',
            status: 'Completed',
            comments: 'Tires replaced with new ones',
            image: 'https://via.placeholder.com/150',
            detailsVisible: false,
        },
        {
            truckId: 'TRK-104',
            maintenanceType: 'Engine Diagnostics',
            maintenanceDate: '2024-10-15',
            nextMaintenance: '2024-11-15',
            status: 'Pending',
            comments: 'Engine check needed for performance issues',
            image: 'https://via.placeholder.com/150',
            detailsVisible: false,
        },
        {
            truckId: 'TRK-105',
            maintenanceType: 'Suspension Repair',
            maintenanceDate: '2024-10-18',
            nextMaintenance: '2024-11-18',
            status: 'Completed',
            comments: 'Suspension repaired and tested',
            image: 'https://via.placeholder.com/150',
            detailsVisible: false,
        },
    ]);
    return (
        <div className="orders">
            <div className="home_sidebar">
                <Sidebar />
            </div>

            <div className="orders_main">
                <Navbar />
                {newpo ? (
                    <AddMaintenanceReport />
                ) : (
                    <MaintenanceTable
                        maintenanceReports={maintenanceReports}
                        addNewReport={() => setNewPO(!newpo)}
                    />
                )}
            </div>
        </div>
    );
}

export default Maintenence;
