import React, { useState } from "react";
import VehicleSafetyChecklist from "./Checklist";
import RepairsPage from "./RepairItem";

const styles = {
  pageContainer: {
    // padding: "20px",
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#f4f6f8", // Soft background
    minHeight: "100vh",
  },
  header: {
    textAlign: "center",
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginBottom: "20px",
    color: "#374151", // Subtle text color
  },
  tabsContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
    borderBottom: "2px solid #e5e7eb",
  },
  tab: {
    flex: 1,
    maxWidth: "200px",
    textAlign: "center",
    padding: "10px 20px",
    cursor: "pointer",
    fontSize: "1rem",
    fontWeight: "500",
    transition: "background-color 0.3s ease",
    borderRadius: "5px 5px 0 0",
  },
  tabActive: {
    backgroundColor: "#2563eb",
    color: "#ffffff",
  },
  tabInactive: {
    backgroundColor: "#e5e7eb",
    color: "#374151",
  },
  tabContent: {
    // padding: "20px",
    backgroundColor: "#ffffff",
    border: "1px solid #e5e7eb",
    borderRadius: "5px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    color: "#374151",
  },
};

const MaintenancePage = () => {
  const [activeTab, setActiveTab] = useState("Checklist");

  const tabs = [
    { key: "Checklist", label: "Checklist" },
    { key: "Repairs", label: "Repairs" },
  ];

  return (
    <div style={styles.pageContainer}>
      <div style={styles.tabsContainer}>
        {tabs.map((tab) => (
          <div
            key={tab.key}
            style={{
              ...styles.tab,
              ...(activeTab === tab.key
                ? styles.tabActive
                : styles.tabInactive),
            }}
            onClick={() => setActiveTab(tab.key)}
          >
            {tab.label}
          </div>
        ))}
      </div>
      <div style={styles.tabContent}>
        {activeTab === "Checklist" && <VehicleSafetyChecklist />}
        {activeTab === "Repairs" && <RepairsPage />}
      </div>
    </div>
  );
};

export default MaintenancePage;
