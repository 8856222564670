import React, { useState } from 'react';

const DriverSafetyTalk = () => {
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const [comment, setComment] = useState('');

  const handleAcknowledge = () => {
    setIsAcknowledged(!isAcknowledged);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  const handleSubmitComment = () => {
    // Implement submission logic here
    console.log('Comment submitted:', comment);
    setComment('');
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h1 style={styles.title}>Winter Driving Safety Guidelines</h1>
        <div style={styles.metaInfo}>
          <span style={styles.iconStyle}>🕒</span>
          <span>December 14, 2024</span>
        </div>
      </div>

      <div style={styles.contentSection}>
        <h2 style={styles.sectionHeading}>Overview</h2>
        <div style={styles.sectionContent}>
          <p>
            The Winter Driving Safety Talk covers critical safety measures to ensure safe driving during winter conditions. It's important to be prepared for the challenges that come with driving in cold temperatures, snow, and ice.
          </p>
        </div>
      </div>

      <div style={styles.contentSection}>
        <h2 style={styles.sectionHeading}>Key Topics</h2>
        <ul style={styles.listStyle}>
          <li>Winter Driving Equipment</li>
          <li>Handling Snow and Ice</li>
          <li>Vehicle Maintenance</li>
          <li>Emergency Preparedness</li>
        </ul>
      </div>

      <div style={styles.acknowledgementContainer}>
        <div
          style={styles.acknowledgementButton}
          onClick={handleAcknowledge}
        >
          <span
            style={{
              ...styles.iconStyle,
              color: isAcknowledged ? '#2ecc71' : '#95a5a6',
            }}
          >
            ✔️
          </span>
          <span>
            {isAcknowledged ? 'Acknowledged' : 'Click to Acknowledge'}
          </span>
        </div>
      </div>

      <div style={styles.commentsSection}>
        <h3 style={styles.sectionHeading}>Comments</h3>
        <textarea
          style={styles.commentInput}
          value={comment}
          onChange={handleCommentChange}
          placeholder="Enter your comment..."
        />
        <button
          style={styles.submitButton}
          onClick={handleSubmitComment}
        >
          Submit Comment
        </button>
      </div>

      <div style={styles.commentsSection}>
        <h3 style={styles.sectionHeading}>Previous Comments</h3>
        <div style={styles.commentContainer}>
          <p><strong>John Doe:</strong> The talk was very informative, especially about emergency preparedness.</p>
        </div>
        <div style={styles.commentContainer}>
          <p><strong>Jane Smith:</strong> I found the snow and ice handling tips to be really useful.</p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '28rem',
    margin: '0 auto',
    padding: '1rem',
    backgroundColor: 'white',
    minHeight: '100vh',
    fontFamily: 'Arial, sans-serif',
  },
  header: {
    marginBottom: '1rem',
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#2c3e50',
  },
  metaInfo: {
    display: 'flex',
    alignItems: 'center',
    color: '#7f8c8d',
    marginTop: '0.5rem',
  },
  iconStyle: {
    marginRight: '0.5rem',
    width: '1rem',
    height: '1rem',
  },
  contentSection: {
    marginBottom: '1rem',
  },
  sectionHeading: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    color: '#34495e',
    marginBottom: '0.5rem',
  },
  sectionContent: {
    backgroundColor: '#f8f9fa',
    padding: '0.75rem',
    borderRadius: '0.5rem',
  },
  listStyle: {
    paddingLeft: '1.25rem',
    color: '#2c3e50',
  },
  acknowledgementContainer: {
    marginTop: '1.5rem',
    marginBottom: '1rem',
    borderTop: '1px solid #e0e0e0',
    paddingTop: '1rem',
  },
  acknowledgementButton: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.75rem',
    borderRadius: '0.5rem',
    cursor: 'pointer',
    backgroundColor: '#f0f0f0',
  },
  commentsSection: {
    marginTop: '1.5rem',
  },
  commentInput: {
    width: '100%',
    padding: '0.5rem',
    border: '1px solid #d0d0d0',
    borderRadius: '0.5rem',
    marginBottom: '0.5rem',
  },
  submitButton: {
    backgroundColor: '#3498db',
    color: 'white',
    padding: '0.5rem 1rem',
    borderRadius: '0.5rem',
    border: 'none',
    cursor: 'pointer',
  },
  commentContainer: {
    backgroundColor: '#f8f9fa',
    padding: '0.75rem',
    borderRadius: '0.5rem',
    marginBottom: '0.75rem',
  },
};

export default DriverSafetyTalk;
