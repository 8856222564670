import React, { useState } from 'react';

// Styles for the form
const styles = {
    form: {
        marginTop: '40px',
        backgroundColor: '#f9f9f9',
        padding: '30px',
        borderRadius: '8px',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.05)',
    },
    formHeader: {
        fontSize: '24px',
        color: '#333',
        marginBottom: '20px',
        fontWeight: '500',
    },
    formInput: {
        width: '100%',
        padding: '12px',
        marginBottom: '15px',
        borderRadius: '6px',
        border: '1px solid #ccc',
        fontSize: '14px',
        color: '#555',
        transition: 'border-color 0.3s ease',
    },
    formTextarea: {
        width: '100%',
        padding: '12px',
        marginBottom: '15px',
        borderRadius: '6px',
        border: '1px solid #ccc',
        fontSize: '14px',
        color: '#555',
        minHeight: '100px',
        transition: 'border-color 0.3s ease',
    },
    submitButton: {
        padding: '12px 25px',
        backgroundColor: '#007BFF',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        transition: 'background-color 0.3s ease',
    },
};

function AddMaintenanceReport({ addReport }) {
    const [formData, setFormData] = useState({
        truckId: '',
        maintenanceType: '',
        maintenanceDate: '',
        nextMaintenance: '',
        status: '',
        comments: '',
        image: null,
    });

    const [selectedTruck, setSelectedTruck] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleTruckSelection = (e) => {
        setSelectedTruck(e.target.value);
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setFormData((prevData) => ({
                ...prevData,
                image: reader.result,
            }));
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const handleAddReport = (e) => {
        e.preventDefault();
        if (
            formData.truckId &&
            formData.maintenanceType &&
            formData.maintenanceDate &&
            formData.nextMaintenance &&
            formData.status &&
            formData.comments &&
            formData.image
        ) {
            addReport(formData);
            setFormData({
                truckId: '',
                maintenanceType: '',
                maintenanceDate: '',
                nextMaintenance: '',
                status: '',
                comments: '',
                image: null,
            });
        }
    };

    return (
        <form onSubmit={handleAddReport} style={styles.form}>
            <h2 style={styles.formHeader}>Add New Maintenance Report</h2>

            <select
                name="truckId"
                value={selectedTruck}
                onChange={handleTruckSelection}
                style={styles.formInput}
                required
            >
                <option value="">Select Truck</option>
                <option value="TRK-101">TRK-101</option>
                <option value="TRK-102">TRK-102</option>
                <option value="TRK-103">TRK-103</option>
                {/* Add more trucks as necessary */}
            </select>

            <input
                type="text"
                name="maintenanceType"
                placeholder="Maintenance Type"
                value={formData.maintenanceType}
                onChange={handleInputChange}
                style={styles.formInput}
                required
            />

            <input
                type="date"
                name="maintenanceDate"
                value={formData.maintenanceDate}
                onChange={handleInputChange}
                style={styles.formInput}
                required
            />

            <input
                type="date"
                name="nextMaintenance"
                value={formData.nextMaintenance}
                onChange={handleInputChange}
                style={styles.formInput}
                required
            />

            <select
                name="status"
                value={formData.status}
                onChange={handleInputChange}
                style={styles.formInput}
                required
            >
                <option value="">Select Status</option>
                <option value="Completed">Completed</option>
                <option value="Pending">Pending</option>
            </select>

            <textarea
                name="comments"
                placeholder="Comments"
                value={formData.comments}
                onChange={handleInputChange}
                style={styles.formTextarea}
                required
            />

            <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                style={styles.formInput}
                required
            />

            <button type="submit" style={styles.submitButton}>
                Add Report
            </button>
            <button type="submit" style={styles.submitButton}>
                cancel
            </button>
        </form>
    );
}

export default AddMaintenanceReport;
