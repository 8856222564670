import React, { useState } from 'react';
import VehicleChecklist from './CheckList';

// Styles for the table and the button
const styles = {
    tabContainer: {
        display: 'flex',
        marginBottom: '20px',
        borderBottom: '2px solid #007BFF', // Blue line for tab selection
    },
    tab: {
        padding: '10px 20px',
        cursor: 'pointer',
        fontSize: '16px',
        fontWeight: 'bold',
        border: 'none',
        backgroundColor: '#f1f1f1',
        transition: 'background-color 0.3s ease',
    },
    activeTab: {
        backgroundColor: '#007BFF',
        color: '#fff',
    },
    tableContainer: {
        marginTop: '40px',
        marginBottom: '20px',
        marginLeft: '20px',
        marginRight: '20px',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        marginTop: '20px',
    },
    tableHeader: {
        backgroundColor: '#007BFF', // Blue header for a professional look
        color: '#fff',
        padding: '12px',
        fontSize: '16px',
        textAlign: 'left',
        fontWeight: 'bold',
    },
    tableRow: {
        borderBottom: '1px solid #ddd',
    },
    tableData: {
        padding: '12px',
        fontSize: '14px',
        color: '#555',
    },
    viewButton: {
        padding: '8px 16px',
        backgroundColor: '#28a745', // Green for view button
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '14px',
        transition: 'background-color 0.3s ease',
    },
    addButton: {
        padding: '10px 20px',
        backgroundColor: '#007BFF', // Blue for Add button
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        transition: 'background-color 0.3s ease',
        float: 'right',
        marginTop: '-35px', // Positioning button near top right
    },
    detailsContainer: {
        marginTop: '15px',
        padding: '20px',
        backgroundColor: '#f1f1f1',
        borderRadius: '8px',
        display: 'none',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    image: {
        maxWidth: '100%',
        borderRadius: '10px',
        marginTop: '10px',
    },
};

function MaintenanceReportTable({
    maintenanceReports,
    toggleDetails,
    addNewReport,
    checklistReports,
}) {
    const [activeTab, setActiveTab] = useState('maintenance'); // State for active tab

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div>
            {/* Tabs for switching between Maintenance List and Checklist List */}
            <div style={styles.tabContainer}>
                <button
                    type="submit"
                    style={
                        activeTab === 'maintenance'
                            ? { ...styles.tab, ...styles.activeTab }
                            : styles.tab
                    }
                    onClick={() => handleTabClick('maintenance')}
                >
                    Maintenance List
                </button>
                <button
                    type="submit"
                    style={
                        activeTab === 'checklist'
                            ? { ...styles.tab, ...styles.activeTab }
                            : styles.tab
                    }
                    onClick={() => handleTabClick('checklist')}
                >
                    Checklist List
                </button>
            </div>

            {/* Render Maintenance Table or Checklist Table based on the active tab */}
            {activeTab === 'maintenance' && (
                <div style={styles.tableContainer}>
                    <button type="submit" style={styles.addButton} onClick={addNewReport}>
                        Add New Entry
                    </button>
                    <table style={styles.table}>
                        <thead>
                            <tr>
                                <th style={styles.tableHeader}>Truck ID</th>
                                <th style={styles.tableHeader}>Maintenance Type</th>
                                <th style={styles.tableHeader}>Maintenance Date</th>
                                <th style={styles.tableHeader}>Next Maintenance</th>
                                <th style={styles.tableHeader}>Status</th>
                                <th style={styles.tableHeader}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {maintenanceReports?.map((report, index) => (
                                <>
                                    <tr style={styles.tableRow}>
                                        <td style={styles.tableData}>{report.truckId}</td>
                                        <td style={styles.tableData}>{report.maintenanceType}</td>
                                        <td style={styles.tableData}>{report.maintenanceDate}</td>
                                        <td style={styles.tableData}>{report.nextMaintenance}</td>
                                        <td style={styles.tableData}>{report.status}</td>
                                        <td style={styles.tableData}>
                                            <button
                                                type="submit"
                                                style={styles.viewButton}
                                                onClick={() => toggleDetails(index)}
                                            >
                                                {report.detailsVisible
                                                    ? 'Hide Details'
                                                    : 'View Details'}
                                            </button>
                                        </td>
                                    </tr>
                                    {report.detailsVisible && (
                                        <tr style={styles.tableRow}>
                                            <td colSpan="6" style={styles.detailsContainer}>
                                                <h3>Maintenance Details</h3>
                                                <p>
                                                    <strong>Comments:</strong> {report.comments}
                                                </p>
                                                <img
                                                    src={report.image}
                                                    alt="Maintenance "
                                                    style={styles.image}
                                                />
                                            </td>
                                        </tr>
                                    )}
                                </>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {activeTab === 'checklist' && (
                <div style={styles.tableContainer}>
                    <VehicleChecklist />
                </div>
            )}
        </div>
    );
}

export default MaintenanceReportTable;
