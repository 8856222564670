import React, { useState } from 'react';

const styles = {
    container: {
        maxWidth: '600px',
        margin: '50px auto',
        padding: '20px',
        borderRadius: '10px',
        backgroundColor: '#f9f9f9',
        boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
    },
    title: {
        fontSize: '24px',
        textAlign: 'center',
        marginBottom: '20px',
        color: '#333',
    },
    input: {
        width: '100%',
        padding: '10px',
        marginBottom: '15px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        boxSizing: 'border-box',
    },
    textArea: {
        width: '100%',
        height: '150px',
        padding: '10px',
        marginBottom: '15px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        boxSizing: 'border-box',
    },
    fileInput: {
        marginBottom: '15px',
    },
    imagePreview: {
        maxWidth: '100%',
        marginBottom: '15px',
        borderRadius: '5px',
    },
    buttonRow: {
        display: 'flex',
        gap: '10px',
        marginTop: '15px',
    },
    cancelButton: {
        padding: '10px 20px',
        backgroundColor: '#6c757d',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '14px',
        flex: '0 0 auto',
    },
    submitButton: {
        padding: '10px',
        backgroundColor: '#007BFF',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '16px',
        flex: '1',
    },
    successMessage: {
        marginTop: '20px',
        color: 'green',
        textAlign: 'center',
    },
};

function AddBlogPage({ onClose }) {
    const [blogData, setBlogData] = useState({
        title: '',
        content: '',
        image: null,
    });

    const [successMessage, setSuccessMessage] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setBlogData({ ...blogData, [name]: value });
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setBlogData({ ...blogData, image: reader.result });
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const blogs = JSON.parse(localStorage.getItem('blogs')) || [];
        blogs.push(blogData);
        localStorage.setItem('blogs', JSON.stringify(blogs));
        setBlogData({ title: '', content: '', image: null });
        setSuccessMessage('Blog added successfully!');
        setTimeout(() => setSuccessMessage(''), 3000);
    };

    const handleCancel = () => {
        setBlogData({ title: '', content: '', image: null });
    };

    return (
        <div style={styles.container}>
            <h1 style={styles.title}>Add New Blog</h1>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="title"
                    placeholder="Blog Title"
                    value={blogData.title}
                    onChange={handleInputChange}
                    style={styles.input}
                    required
                />
                <textarea
                    name="content"
                    placeholder="Blog Content"
                    value={blogData.content}
                    onChange={handleInputChange}
                    style={styles.textArea}
                    required
                />
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                    style={styles.fileInput}
                />
                {blogData.image && (
                    <img src={blogData.image} alt="Preview" style={styles.imagePreview} />
                )}
                <div style={styles.buttonRow}>
                    <button type="submit" style={styles.submitButton}>
                        Submit
                    </button>
                    <button type="button" style={styles.cancelButton} onClick={() => onClose()}>
                        Cancel
                    </button>
                </div>
            </form>
            {successMessage && <p style={styles.successMessage}>{successMessage}</p>}
        </div>
    );
}

export default AddBlogPage;
