import React, { useState } from "react";

// Styles object defined outside the component
const styles = {
  container: {
    // maxWidth: "42rem",
    margin: "0 auto",
    padding: "1rem",
    fontFamily: "Arial, sans-serif",
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "1.5rem",
  },
  statusIndicator: {
    padding: "1rem",
    borderRadius: "0.5rem",
    textAlign: "center",
    marginBottom: "1.5rem",
    fontWeight: "bold",
  },
  sectionContainer: {
    backgroundColor: "white",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    borderRadius: "0.5rem",
    padding: "1rem",
    marginBottom: "1rem",
  },
  sectionTitle: {
    fontSize: "1.25rem",
    fontWeight: "bold",
    marginBottom: "0.75rem",
  },
  itemRow: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    borderBottom: "1px solid #e5e7eb",
  },
  itemHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "0.5rem",
  },
  itemLabel: {
    textTransform: "capitalize",
    flexGrow: 1,
  },
  buttonContainer: {
    display: "flex",
    gap: "0.5rem",
  },
  okayButton: {
    padding: "0.25rem 0.75rem",
    borderRadius: "9999px",
    cursor: "pointer",
  },
  notOkayButton: {
    padding: "0.25rem 0.75rem",
    borderRadius: "9999px",
    cursor: "pointer",
  },
  commentTextarea: {
    width: "100%",
    padding: "0.5rem",
    border: "1px solid #d1d5db",
    borderRadius: "0.375rem",
    outline: "none",
  },
  errorText: {
    color: "#ef4444",
    fontSize: "0.875rem",
    marginTop: "0.25rem",
  },
  saveButton: {
    width: "100%",
    padding: "0.75rem",
    backgroundColor: "#3b82f6",
    color: "white",
    border: "none",
    borderRadius: "0.5rem",
    fontSize: "1rem",
    fontWeight: "bold",
    cursor: "pointer",
    marginTop: "1rem",
    transition: "background-color 0.3s ease",
  },
  saveButtonHover: {
    backgroundColor: "#2563eb",
  },
};

const VehicleSafetyChecklistItems = () => {
  const [checklist, setChecklist] = useState({
    mechanicalComponents: {
      safetyBelts: { status: null, comment: "" },
      brakes: { status: null, comment: "" },
      steering: { status: null, comment: "" },
      engine: { status: null, comment: "" },
      transmission: { status: null, comment: "" },
      heater: { status: null, comment: "" },
      airConditioning: { status: null, comment: "" },
      wipers: { status: null, comment: "" },
      radio: { status: null, comment: "" },
      horn: { status: null, comment: "" },
    },

    lights: {
      headlightsHighBeam: { status: null, comment: "" },
      headlightsLowBeam: { status: null, comment: "" },
      turnSignals: { status: null, comment: "" },
      brakeLights: { status: null, comment: "" },
      tailLights: { status: null, comment: "" },
    },

    bodyAndDocuments: {
      doors: { status: null, comment: "" },
      windows: { status: null, comment: "" },
      windshield: { status: null, comment: "" },
      windowWasher: { status: null, comment: "" },
      insurance: { status: null, comment: "" },
      inspection: { status: null, comment: "" },
    },

    safetyEquipment: {
      tiresTread: { status: null, comment: "" },
      tiresCondition: { status: null, comment: "" },
      tireInflation: { status: null, comment: "" },
      lugWrench: { status: null, comment: "" },
      jack: { status: null, comment: "" },
      fireExtinguisher: { status: null, comment: "" },
      firstAidKit: { status: null, comment: "" },
    },

    fluidLevels: {
      radiator: { status: null, comment: "" },
      engineOil: { status: null, comment: "" },
      autoTransmissionOil: { status: null, comment: "" },
      powerSteeringFluid: { status: null, comment: "" },
      brakesFluid: { status: null, comment: "" },
    },

    accidentInformation: {
      informationPacket: { status: null, comment: "" },
    },
  });

  // State to track hover and active states for save button
  const [isHoveringSave, setIsHoveringSave] = useState(false);

  // Utility function to format camel case to readable text
  const formatLabel = (key) => {
    return key
      .replace(/([A-Z])/g, " $1")
      .toLowerCase()
      .replace(/^./, (char) => char.toUpperCase());
  };

  // Update item status and handle comment requirement
  const updateItemStatus = (section, item, status) => {
    setChecklist((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [item]: {
          status: status,
          comment: status === true ? "" : prev[section][item].comment,
        },
      },
    }));
  };

  // Update comment for a specific item
  const updateComment = (section, item, comment) => {
    setChecklist((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        [item]: {
          ...prev[section][item],
          comment: comment,
        },
      },
    }));
  };

  // Check if a section is completely reviewed
  const isSectionComplete = (section) => {
    return Object.values(checklist[section]).every(
      (value) => value.status !== null
    );
  };

  // Check if a section has any issues
  const hasSectionIssues = (section) => {
    return Object.values(checklist[section]).some(
      (value) => value.status === false
    );
  };

  // Calculate overall review status
  const calculateOverallStatus = () => {
    const allSectionsReviewed = Object.values(checklist).every((section) =>
      Object.values(section).every((item) => item.status !== null)
    );

    const hasIssues = Object.values(checklist).some((section) =>
      Object.values(section).some((item) => item.status === false)
    );

    return {
      allReviewed: allSectionsReviewed,
      hasIssues,
    };
  };

  // Save checklist handler
  const handleSave = () => {
    // Check if all sections are reviewed and valid
    const { allReviewed, hasIssues } = calculateOverallStatus();

    // Validate all comments for 'Not Okay' items
    const hasEmptyComments = Object.values(checklist).some((section) =>
      Object.entries(section).some(
        ([key, value]) => value.status === false && value.comment.trim() === ""
      )
    );

    if (!allReviewed) {
      alert("Please complete all sections of the checklist.");
      return;
    }

    if (hasEmptyComments) {
      alert("Please provide comments for all items marked as Not Okay.");
      return;
    }

    // Simulate saving - in a real app, you'd send this to a backend
    console.log("Saving checklist:", checklist);
    alert(
      hasIssues
        ? "Checklist saved. Vehicle needs attention."
        : "Checklist saved. Vehicle is safe and ready."
    );
  };

  // Render a section of checklist items
  const renderSection = (sectionTitle, sectionKey, items) => {
    const determineSectionTitleColor = () => {
      if (!isSectionComplete(sectionKey)) return "#1f2937";
      return hasSectionIssues(sectionKey) ? "#dc2626" : "#16a34a";
    };

    return (
      <div
        style={{
          ...styles.sectionContainer,
          border: hasSectionIssues(sectionKey) ? "2px solid #ef4444" : "none",
        }}
      >
        <h2
          style={{
            ...styles.sectionTitle,
            color: determineSectionTitleColor(),
          }}
        >
          {sectionTitle}
        </h2>
        {Object.entries(items).map(([key, value]) => (
          <div key={key} style={styles.itemRow}>
            <div style={styles.itemHeader}>
              <span style={styles.itemLabel}>{formatLabel(key)}</span>
              <div style={styles.buttonContainer}>
                <button
                  onClick={() => updateItemStatus(sectionKey, key, true)}
                  style={{
                    ...styles.okayButton,
                    backgroundColor:
                      value.status === true ? "#22c55e" : "#dcfce7",
                    color: value.status === true ? "white" : "#16a34a",
                  }}
                >
                  
                </button>
                <button
                  onClick={() => updateItemStatus(sectionKey, key, false)}
                  style={{
                    ...styles.notOkayButton,
                    backgroundColor:
                      value.status === false ? "#ef4444" : "#fee2e2",
                    color: value.status === false ? "white" : "#dc2626",
                  }}
                >
                  X
                </button>
              </div>
            </div>

            {/* Comment section for 'Not Okay' status */}
            {value.status === false && (
              <div style={{ marginTop: "0.5rem" }}>
                <textarea
                  placeholder="Describe the issue (required)"
                  style={styles.commentTextarea}
                  rows={3}
                  value={value.comment}
                  onChange={(e) =>
                    updateComment(sectionKey, key, e.target.value)
                  }
                  required
                />
                {value.comment.trim() === "" && (
                  <p style={styles.errorText}>
                    ⚠️ Comment is required for items marked as Not Okay
                  </p>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const { allReviewed, hasIssues } = calculateOverallStatus();

  // Determine status indicator styles
  const getStatusIndicatorStyle = () => {
    const baseStyle = { ...styles.statusIndicator };
    if (!allReviewed) {
      baseStyle.backgroundColor = "#fef3c7";
      baseStyle.color = "#92400e";
    } else if (hasIssues) {
      baseStyle.backgroundColor = "#fee2e2";
      baseStyle.color = "#991b1b";
    } else {
      baseStyle.backgroundColor = "#dcfce7";
      baseStyle.color = "#166534";
    }
    return baseStyle;
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Vehicle Safety Checklist</h1>

      {/* Overall Status Indicator */}
      <div style={getStatusIndicatorStyle()}>
        {!allReviewed && "Complete all sections"}
        {allReviewed && hasIssues && "Issues found - Vehicle needs attention"}
        {allReviewed && !hasIssues && "Vehicle is safe and ready"}
      </div>

      {/* Checklist Sections */}
      {renderSection(
        "Mechanical Components",
        "mechanicalComponents",
        checklist.mechanicalComponents
      )}
      {renderSection("Lights", "lights", checklist.lights)}
      {renderSection(
        "Body and Documents",
        "bodyAndDocuments",
        checklist.bodyAndDocuments
      )}
      {renderSection(
        "Safety Equipment",
        "safetyEquipment",
        checklist.safetyEquipment
      )}
      {renderSection("Fluid Levels", "fluidLevels", checklist.fluidLevels)}
      {renderSection(
        "Accident Preparation",
        "accidentInformation",
        checklist.accidentInformation
      )}

      {/* Save Button */}
      <button
        style={{
          ...styles.saveButton,
          ...(isHoveringSave ? styles.saveButtonHover : {}),
        }}
        onMouseEnter={() => setIsHoveringSave(true)}
        onMouseLeave={() => setIsHoveringSave(false)}
        onClick={handleSave}
        disabled={!calculateOverallStatus().allReviewed}
      >
        Save Checklist
      </button>
      {/* <BottomNavigation/> */}
    </div>
  );
};

export default VehicleSafetyChecklistItems;
