/* eslint-disable no-constant-condition */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import Navbar from '../../Components/Navbar/Navbar';
import Sidebar from '../../Components/Sidebar/Sidebar';
import AddAllocation from './AddAllocation';
import ShipmentTable from './TableList';
import './allocation.scss';

function Lists({ type }) {
    const [newpo, setNewPO] = useState();
    return (
        <div className="list_page">
            <div className="home_sidebar">
                <Sidebar />
            </div>

            <div className="list_page_main">
                <Navbar />

                {/* mui data table */}
                <div className="data_table">
                    <div className="btnn">
                        {!newpo && (
                            <button type="button" onClick={() => setNewPO(!newpo)}>
                                Add New {type}
                            </button>
                        )}
                    </div>
                    {newpo ? <AddAllocation onClose={() => setNewPO(!newpo)} /> : <ShipmentTable />}
                </div>
            </div>
        </div>
    );
}

export default Lists;
