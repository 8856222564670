import React from 'react';

const styles = {
    container: {
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#f9f9f9',
    },
    title: {
        textAlign: 'center',
        marginBottom: '20px',
        color: '#333',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    },
    header: {
        backgroundColor: '#007BFF',
        color: '#fff',
        padding: '10px',
        textAlign: 'left',
        border: '1px solid #ddd',
    },
    cell: {
        padding: '10px',
        border: '1px solid #ddd',
        textAlign: 'left',
        backgroundColor: '#fff',
    },
    card: {
        display: 'block',
        backgroundColor: '#fff',
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        marginBottom: '10px',
        padding: '15px',
        borderRadius: '8px',
        fontSize: '14px',
    },
    cardHeader: {
        fontWeight: 'bold',
        color: '#007BFF',
        marginBottom: '8px',
    },
    cardLabel: {
        fontWeight: 'bold',
        color: '#555',
        marginRight: '5px',
    },
    cardValue: {
        color: '#333',
    },
    // Media query for mobile responsiveness
    '@media (max-width: 768px)': {
        table: {
            display: 'none', // Hide table on mobile
        },
        card: {
            display: 'block', // Show card view on mobile
        },
        cardHeader: {
            textAlign: 'center',
        },
    },
};

function ShipmentTable() {
    const [shipments, setShipments] = React.useState([]);
    React.useEffect(() => {
        const storedShipments = localStorage.getItem('shipments');
        if (storedShipments) {
            setShipments(JSON.parse(storedShipments));
        }
    }, []);

    return (
        <div style={styles.container}>
            {/* Table for desktop */}
            <table style={styles.table}>
                <thead>
                    <tr>
                        <th style={styles.header}>Shipment No</th>
                        <th style={styles.header}>Driver Name</th>
                        <th style={styles.header}>Vehicle Reg. No</th>
                        <th style={styles.header}>Trip</th>
                        <th style={styles.header}>Customer Name</th>
                        <th style={{ ...styles.header, textAlign: 'right' }}>Cases</th>
                    </tr>
                </thead>
                <tbody>
                    {shipments?.map((row, index) => (
                        <tr>
                            <td style={styles.cell}>{row.ShipmentNO}</td>
                            <td style={styles.cell}>{row.DriverName}</td>
                            <td style={styles.cell}>{row.VehicleRegNO}</td>
                            <td style={styles.cell}>{row.Trip}</td>
                            <td style={styles.cell}>{row.CustomerName}</td>
                            <td style={{ ...styles.cell, textAlign: 'right' }}>{row.Cases}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Card layout for mobile */}
            <div style={{ display: 'none' }} className="mobile-cards">
                {shipments?.map((row, index) => (
                    <div style={styles.card}>
                        <div style={styles.cardHeader}>Shipment {row.ShipmentNO}</div>
                        <div>
                            <span style={styles.cardLabel}>Driver:</span>
                            <span style={styles.cardValue}>{row.DriverName}</span>
                        </div>
                        <div>
                            <span style={styles.cardLabel}>Vehicle Reg. No:</span>
                            <span style={styles.cardValue}>{row.VehicleRegNO}</span>
                        </div>
                        <div>
                            <span style={styles.cardLabel}>Trip:</span>
                            <span style={styles.cardValue}>{row.Trip}</span>
                        </div>
                        <div>
                            <span style={styles.cardLabel}>Customer:</span>
                            <span style={styles.cardValue}>{row.CustomerName}</span>
                        </div>
                        <div>
                            <span style={styles.cardLabel}>Cases:</span>
                            <span style={styles.cardValue}>{row.Cases}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ShipmentTable;
